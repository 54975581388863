@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import "variable.scss";
// @import "~ng-zorro-antd/ng-zorro-antd.less";
// @primary-color: #133C5C;

/* You can add global styles to this file, and also import other style files */
// body{
//     background-color: #E5EFF9 !important;
// }
/* Header custom css */
body, html {
    height: auto !important;
    // font-family: "DM Sans";
}
.ria-bd-icon {
    font-size: 20px;
	color: #4472C4;
}
.header {
    width: 100% !important;
    min-height: 60px !important;
    height: 70px;
    z-index: 699 !important;
}
.header-pin {
    padding-left: 274px !important;
}
.header-unpin {
    padding-left: 70px !important;
}
.header-padding {
    padding-left: 0px !important;
}
.header .search-link {
    margin-left: 0px !important;
}
.page-sidebar .sidebar-header {
    // background-color: unset !important;
    background-color: #133C5C !important;
    border-bottom: unset !important;
}
body.menu-behind .page-sidebar {
    z-index: 999 !important;
    //width: 250px !important;
}
/* End Header custom css */

.typehead .dropdown-menu {
    width: auto !important;
}
.search-bar:focus {
    background-color: #e1e1e1 !important;
}
.pg-search-icon {
    position: absolute;
    margin-left: 0px;
    z-index: 9;
    background: #e1e1e1;
    height: 35px;
    line-height: 35px !important;
    width: 40px;
    text-align: center;
}
.search-bar-cursor {
    margin-left: 25px !important;
}

.brand-cus-wid {
    width: 184px !important;
}

.typeahead-loading {
    position: absolute;
    bottom: -30px;
    padding: 10px;
    background: #fff;
    right: 0;
    left: 0;
    opacity: 1;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    z-index: 99999;
}
.header .search-link{
    opacity: 1;
}

.grade-label {
    padding: 0;
    margin: 0 auto;
}

.grade-label {
    display: block;
    border: 0;
    max-width: 25px;
    width: 100%;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 5px;
    margin-bottom: 0px;
    background:gray;
}

.grade-label.grade-F{
    background: #FF0000;
}
.grade-label.grade-D{
    background: #FF410B;
}
.grade-label.grade-C{
    background: #FF7E00;
}
.grade-label.grade-B{
    background: #C7FF00;
}
.grade-label.grade-A{

    background: #00FF1B;
}
.grade-text.grade-F{
    color: #FF0000;
}
.grade-text.grade-D{
    color: #FF410B;
}
.grade-text.grade-C{
    color: #FF7E00;
}
.grade-text.grade-B{
    color: #C7FF00;
}
.grade-text.grade-A{

    color: #00FF1B;
}

.large-text {
    font-size: 24px !important;
}
.page-sidebar {
    overflow: visible !important;
    // background-image: linear-gradient(180deg,#1fbde0 0,#133C5C)!important;
}
// .sidebar-header {
//     background-image: linear-gradient(180deg,#1fbde0 0,#133C5C)!important;
// }

.pg-select-dropdown {
    .pg-select-dropdown-menu {
      .pg-select-dropdown-menu-item:hover {
        white-space: normal !important;
      }
    }
}

.tab-wrapper .nav-tabs-simple .active-bar{
    background-color: #133C5C !important;
}

typeahead-container {
    // height: calc(100% - 30px) !important;
    max-height: 33vh;
    overflow-x: hidden;
    &::-webkit-scrollbar-track
    {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #ffffff;
    }

    &::-webkit-scrollbar
    {
        width: 3px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
        background-color: #5d5e5d;
    }
    flex-grow: 1;
}

.down-repo {
    text-align: center;
    font-size: 24px;
}
/* set datatables width of heading and column */
.wid-220 {
    min-width: 22% !important;
}
.wid-210 {
    min-width: 21% !important;
}
.wid-200 {
    min-width: 20% !important;
}
.wid-180 {
    min-width: 18% !important;
}
.wid-170 {
    min-width: 17% !important;
}
.wid-160 {
    min-width: 16% !important;
}
.wid-150 {
    min-width: 15% !important;
}
.wid-140 {
    min-width: 14% !important;
}
.wid-130 {
    min-width: 13% !important;
}
.wid-120 {
    min-width: 12% !important;
}
.wid-110 {
    min-width: 11% !important;
}
.wid-100 {
    min-width: 10% !important;
}
.wid-90 {
    min-width: 9% !important;
}
.wid-80 {
    min-width: 8% !important;
}
.wid-70 {
    min-width: 7% !important;
}
.wid-60 {
    min-width: 6% !important;
}
.wid-50 {
    min-width: 5% !important;
}
.wid-40 {
    min-width: 4% !important;
}
.wid-30 {
    min-width: 3% !important;
}
.wid-20 {
    min-width: 2% !important;
}
.wid-10 {
    min-width: 1% !important;
}
.cur-pointer {
    cursor: pointer;
}
.ant-tabs-tab+.ant-tabs-tab {
    margin: 0 !important;
}
.horizontal-tab .ant-tabs-tab, .horizontal-tab2 .ant-tabs-tab {
    display: block;
    border-radius: 0;
    padding: 13px 20px;
    margin-right: 0;
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    letter-spacing: .06em;
    font-size: 10.5px;
    min-width: 70px;
    text-transform: uppercase;
    border-color: transparent;
    position: relative;
    line-height: 1.7em;

}
.horizontal-tab .ant-tabs-tab-btn, .horizontal-tab2 .ant-tabs-tab-btn {
    color: #000000 !important;
}
.horizontal-tab .ant-tabs-tab:hover, .horizontal-tab2 .ant-tabs-tab:hover{
    font-weight: 600 !important;
}
.horizontal-tab .ant-tabs-tab-active, .horizontal-tab2 .ant-tabs-tab-active {
    border-bottom: 3px solid #133C5C !important;
}
.vertical-tab .ant-tabs-tab-btn{
    color: #000000 !important;
    text-shadow:none !important;
    text-transform: uppercase;
    font-size: 10.5px;
}
.horizontal-tab .ant-tabs-tab:hover .ant-tabs-tab-btn, .vertical-tab .ant-tabs-tab:hover .ant-tabs-tab-btn {
    font-weight: 600 !important;
}
.vertical-tab .ant-tabs-tab-active{
    border-right: 3px solid #133C5C !important;
}
.ant-slider-track {
    background-color: #133C5C !important;
}
.ant-slider-handle {
    border: 2px solid #133C5C !important;
}

// .body-bg-image:after{
    // position: absolute;
    // left: 0;
    // top: 0;
    // bottom: 0;
    // width:50%;
    // background:url("assets/img/left-side1.svg");
    // background-size: cover;
    // content: '';
    // z-index: -1;
// }


.body-bg-image {
    z-index: 0;
    // background-image: linear-gradient(to bottom, #1FBDE0 0%, #133C5C 100%) !important;
    // background-image: url("assets/img/background.png") !important;
    // background-color: #133C5C !important;

    position: fixed;
    // background-color: #15315B !important;

    background: linear-gradient(90deg, #15315B 50%, #f3f3f3 50%);
    background-attachment: fixed;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: bottom !important;
    height: 100vh !important;
}

.mb-btm {
    padding-bottom: 65px !important;
}

.card-body-radius {
    border-radius: 10px !important;
}

.bold-font {
    font-weight: 600;
}
.horizontal-tab .ant-tabs-tab-active{
    background: #133C5C ;
    border-bottom: 3px solid #133C5C !important;
    transition: transform .3s ease 0s !important;
}
.horizontal-tab .ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #ffffff !important;
}
.horizontal-tab .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none;
}
.horizontal-tab .ant-tabs-tab.ng-star-inserted:before {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #133C5C;
    border: 1px solid #133C5C;
    content: "";
    height: calc(100% + 1px);
    left: 0;
    position: absolute;
    top: 0;
    transform: translate3d(0,100%,0);
    transition: transform .3s ease 0s;
    width: 100%;
    z-index: -1;
}
.horizontal-tab .ant-tabs-tab.ng-star-inserted.ant-tabs-tab-active:before {
    transform: translate3d(0,0,0);
    -ms-transform: translate(0,0);
}
.horizontal-tab .ant-tabs-tab-active {
    /* background: #133C5C; */
    /* border-bottom: 3px solid #133C5C !important; */
    transition: transform .3s ease 0s !important;
    color: #000;
    position: relative;
    border: none !important;
    box-shadow: none !important;
    color: #fff !important;
}
.horizontal-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
}
.datatable-row-remove-width {
    padding-left: 5px !important;
    padding-right: 5px !important;
    text-align-last: center !important;
}

.datatable-footer .datatable-pager .pager li a {
    cursor: pointer;
    display: inline-block;
    opacity: 1 !important;
    //border: 1px solid #ddd;
    // width: 20px;
    width: auto;
    height: 28px;
    line-height: 29px;
    text-align: center;
    border-radius: 5px;
}
.datatable-footer .datatable-pager .pager li.disabled a {
    color: rgba(0,0,0,.26)!important;
}
.datatable-footer .datatable-pager .pager li {
    margin-left: 8px !important;
}

.pagination li.pagination-disabled a {
    color: rgba(0,0,0,.26)!important;
}
.pagination {

    li {
        margin-left: 8px !important;
        a {
            padding: 0 5px !important;
            cursor: pointer !important;
            display: inline-block;
            opacity: 1 !important;
            //border: 1px solid #ddd !important;
            min-width: 27px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 5px !important;
        }
    }
    .page-item.active .page-link{
        background-color: #133C5C !important;
        color: #fff;
    }

}

.ngx-datatable .datatable-footer .datatable-pager ul li:not(.disabled).active a {
    background-color: #133C5C !important;
    color: #fff;
}

.notes_container{
    .notes_visibility {
        .pg-select-selection {
            border-radius: 25px !important;
            border: 1px solid #a4a4a4;
            background-color: #f5f6fa;
        }
    }
    button{
        border-radius: 25px !important;
    }
    textarea {
        background-color: #f5f6fa;
    }
}

.task_container{
    .privacy_dropdown{
        .pg-select-selection {
            border-radius: 25px !important;
            border: 1px solid #a4a4a4;
            background-color: #f5f6fa;
        }
    }
    input {
        border-radius: 25px !important;
        background-color: #f5f6fa
    }
    button{
        border-radius: 25px !important;
    }
}

.bg-grn {
    background: $blue_color !important;
}
.color-grn {
    color: $blue_color !important;
}
.color-green {
    color: $green_color !important;
}
.default-btn-grn {
    color: $blue_color !important;
    border-color: $blue_color !important;
}
.ant-btn-grn, .ant-btn-grn:focus, .ant-btn-grn:hover {
    color: #ffffff !important;
    border-color: #74d7b2 !important;
    background: $blue_color !important;
}
.primary-btn-grn {
    background: $blue_color !important;
    color: #fff !important;
    border-color: $blue_color !important;
}
.primary-btn-grey{
    background: $gre-color !important;
    color: #000 !important;
    border-color: $gre-color !important;
}
.default-btn-grey{
    color: $gre-color !important;
    border-color: $gre-color !important;
}
.color-grey{
    color: $gre-color !important;;
}
.bg-grey {
    background: $gre-color !important;
}
.circle-btn-gray {
    background: $gre-color !important;
    color: #000 !important;
    border-color: $gre-color !important;
    border-radius: 25px !important;
}
.circle-btn-grn {
    background: $blue_color !important;
    border-radius: 25px !important;
    color: #fff !important;
    border-color: $blue_color !important;
}

.page-sidebar .sidebar-menu .menu-items {
    // background-image: linear-gradient(to bottom, #1FBDE0 0%, #133C5C 100%) !important;
    background-color:  #133C5C !important;
    background-attachment: fixed;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: bottom !important;
}

.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu{
    position: relative;
    margin-left: -20px !important;
    margin-bottom: 0 !important;

}

.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li{
    line-height: normal !important;
    padding: 5px 20px 5px 40px !important;
}
.menu-pin .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu{padding: 0 10px !important;}
.page-sidebar .sidebar-menu .sub-menu li.open .icon-thumbnail > i{
    color: #fff !important;
}

.page-sidebar .sidebar-menu .sub-menu li.open > a{color: #fff !important;}

.page-sidebar .sidebar-menu .sub-menu li:hover .icon-thumbnail > i, .page-sidebar .sidebar-menu .sub-menu li.active .icon-thumbnail > i, .page-sidebar .sidebar-menu .sub-menu li:hover > a, .page-sidebar .sidebar-menu .sub-menu li.active > a{
    color: #133C5C !important;
}

.page-sidebar .sidebar-menu .sub-menu li:hover, .page-sidebar .sidebar-menu .sub-menu li.active {
    background: #fff !important;
    border-radius: 5px !important;
    // margin: 10px 10px !important;
}

.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
    margin: 5px 0 !important;
}

.page-sidebar .sidebar-menu .sub-menu .icon-thumbnail > i{color: #fff !important;}

.circle-btn-gray {
    background: $gre-color !important;
    color: #000 !important;
    border-color: $gre-color !important;
    border-radius: 25px !important;
}
.advisor_action {
	.datatable-body-cell-label {
		width: 50px !important;
        a {
			display: inline-block;
			vertical-align: top;
			font-size: 14px;
			margin: 0 3px !important;
		}
	}
}
.table.table-condensed{
    overflow: hidden;
    border-radius: 5px 5px 0 0;
}
.ngx-datatable {
    border-radius: 5px 5px 0 0;
}
.plan-pro-search {
	.datatable-header-inner {
		background-color: #133C5C !important;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		.datatable-header-cell-label {
			color: #fff !important;
		}
		.datatable-header-cell-template {
			padding-left: 10px !important;
		}
	}
	.datatable-header {
		.datatable-header-cell.sortable .sort-btn:before {
			color: #fff;
		}
		.datatable-header-cell.sortable .sort-btn::after {
			color: #fff;
		}
	}
}
.card-group .card .card-header .card-title > a.collapsed:after {
	top: 50%;
    transform: translateY(-50%);
    content: "\f078" !important;
}
.card-group .card .card-header .card-title > a:after {
    top: 50% !important;
    transform: translateY(-50%);
    content: "\f077" !important;
}
.ngx-datatable:not(.profile-fees_tbl) {
    .datatable-body {
        .datatable-row-wrapper:nth-child(even){
            .datatable-body-cell{
                background:  #f5f6fa !important;
            }
        }
    }
}
.table {
    tbody {
        tr:nth-child(even){
            td {
                background:  #f5f6fa !important;
            }
        }
    }
}
.profileview{
    .card-group .card .card-header .card-title > a{
        font-weight: 600;
        font-size: 18px;
        padding-bottom: 0;
    }
    .card-group .card .card-header {
        padding: 0;
        min-height:initial;
    }
}
.m-remove {
    margin: 0 !important;
}
.overview-collapse{
    .card-body {
        padding: 0 !important;
    }
}
.bold-text{
    font-weight: 600;
}
.note-bg{
    background-color: #133C5C;
    color: white;
}

.ant-popover-inner-content {
    padding: 12px 5px !important;
    .dropdown-item {
        color: #575757 !important;
        opacity: .9 !important;
        font-size: 13px !important;
    }
    .dropdown-item:hover {
        color: #fff !important;
      background-color: #133C5C;
    }
}

.email-automation-activity-tbl{
    .datatable-row-center{
        background-color: #133C5C !important;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        .datatable-header-cell-label {
            color: #fff !important;
        }
        .datatable-header-cell-template {
            padding-left: 10px !important;
        }
    }
    .datatable-header {
        .datatable-header-cell.sortable .sort-btn:before {
            color: #fff;
        }
        .datatable-header-cell.sortable .sort-btn::after {
            color: #fff;
        }

    }
}
.box-shadow {
    box-shadow: 3px 0px 16px #ccc !important;
    border-radius: 10px !important;
    box-sizing: border-box;
}
.mb28 {
    margin-bottom: 28px !important;
}
.chart-default {
    height: 100% !important;
}
.chart-none {
    height: 0 !important;
}

.container-fluid {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.modal-backdrop.show:nth-of-type(even) {
    z-index: 1051 !important;
}

.modal:nth-of-type(even) {
    z-index: 1052!important;
}

.ql-toolbar.ql-snow {
  .ql-formats {
    margin-right: 0px !important;
  }
  background-color: unset !important;
}
.ql-snow .ql-tooltip.ql-editing input[type=text] {
    background-color: dodgerblue !important;
}
.ql-snow .ql-picker {
    margin-left: 0 !important;
}

.ql-snow .ql-formats {
    height: 35px !important;
}

.ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
    width: 38px !important;
    height: 26px !important;
}

.ql-snow .ql-tooltip a.ql-preview {
    color: #fff !important;
}
.ql-snow .ql-tooltip {
    z-index: 999 !important;
}
.adv-radio {
    [nz-radio] {
        display: block !important;
        height: 32px !important;
        line-height: 32px !important;
    }
}
.profile-logo {
    .upload-list-text {
        display: none !important;
    }
}
.ql-admin {
    .ql-snow {
        height: auto !important;
    }
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    display: flex !important;
    align-items: center !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 100% !important;
}
.ant-select-single .ant-select-selector {
    height: 100% !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    height: 100% !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 100% !important;

}

.advizor-saved {
	.pg-select {
		height: 100% !important;
	}
	.pg-select-selection--multiple {
		height: 100% !important;
	}
}
/* End nz select */

.int-tab {
    .ant-tabs-nav {
        padding-left: 25px !important;
    }
}
.manu-tab {
    .ant-tabs-nav {
        padding-left: 0px !important;
    }
}
.person-profile .title-circle{
    color: #fff !important;
}

.md-responsive {
    display: none;
}

/*** General tablets and phones ***/
@media only screen and (max-width: 2000px) {
    .cus-multiple-control {
        .dropdown-menu {
            min-width: 68em !important;
        }
    }
}
@media (max-width: 1200px) {
    .cus-multiple-control {
        .dropdown-menu {
            min-width: 63em !important;
        }
    }
}
@media (max-width: 991px) {
    .cus-multiple-control {
        .dropdown-menu {
            min-width: 100% !important;
        }
    }
    .log-l-side {
        display: none;
    }
    .body-bg-image {
        background: #f3f3f3 !important;
    }
    .md-responsive {
        display: block;
    }
}

/*** General tablets and phones ***/
@media (min-width: 768px) {
    .pl-off {
       padding-left: 0px !important;
    }
}
.family_office_icon{
	background:green !important;
}
.bank_and_trust_icon{
	background:rgb(91, 1, 182) !important;
}
.circle-dc{
	background-color: rgb(192, 154, 223) !important ;
    color: #ffffff !important;
}
.circle-db{
	background-color: rgb(111, 87, 150) !important;
    color: #ffffff !important;
}
.green-snackbar {
    background-color: #6eda87;
    border-color: #6bac7a;
    color: #ffffff;
    float: right;
}
.red-snackbar {
    background: #B00020;
    border-color: #6bac7a;
    color: #ffffff;
    float: right;
}
.alert-p-border {
    border-right: 2px solid #e2e2e2 !important;
    padding-left: 10px !important;
}
.alert-danger-cus {
    background-color: #e44343;
    color: #ffffff;
    border-color: #933432;
}
.alert-success-cus {
    background-color: #43e473;
    color: #ffffff;
    border-color: #32934f;
}

.bd-r {
    border-right: 1px solid rgba(225, 225, 225, 0.7);
}

.cdk-overlay-pane{
	z-index: 1001 !important;
}

.ant-timeline, .ant-timeline-item {
    font-size: 12px !important;
}
.ant-timeline-item-tail {
    left: 7px !important;
}
.ant-modal-header {
    // padding: 0px !important;
    background-color: #f5f6fa !important;
    font-weight: bold !important;
    padding: 16px 24px !important;
    border-bottom: 1px solid #f0f0f0 !important;
    border-radius: 2px 2px 0 0 !important;

    .modal-title {

    }
}

.ant-modal-title {
    font-weight: bold !important;
}

.modal{
	.close {
        &:after{
            content: '';
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAPJJREFUOE+l0z1KQ0EUxfFfdiA2WgixTmFhLUIQRAshWtiIiJ1liG7A2oDYWItYKeJHo5WuQEWwcAFuQFcgF0Z5DO8lmjflzL1/5pxzb0PN06jZrwg4xyRu0a8A72EFD9iPmiJgAgGZxzLuM8gS7nCC7Z+3XMJMgoxjFh+pcArPeEMHX1WAuF9MkBe0U+EjmljFa/FnVSZu4hTHqXgHa7jJvRmUQhh2kBq6OCozdhBgt5BGD4f/AWzgLJOwjsu/SFjAFZ4yE6eTD2Hu78kltHCNsYoY39MgfZbFGNmHy3NDBinS2SoDXCCmceRRHmmvam/jN8E4LBGbXy2YAAAAAElFTkSuQmCC);
            width: 14px;
            height: 16px;
            background-size: 100%;
            display: block;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

.modal .modal-content {
    border-radius: 0;
}

.modal .modal-header {
    border-radius: 0 !important;
    display: block;
    text-align: left !important;
    padding: 16px 24px !important;
}
.modal-content .close{
    padding: 0 !important;
    width: 16px;
    height: 16px;
    top: 36px !important;
    right: 32px !important;
    opacity: 0.8 !important;
}
.modal-content .close svg{
    width: 100%;
}
.modal .modal-header h5{
    margin: 0;
    padding-right: 20px;
}
.absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
}

.w-56 {
    width: 56%;
}

.btn-primary{color:#fff;background-color:#133C5C !important;border-color:#133C5C !important}
.btn-primary:hover{color:#fff;background-color:#2069a1 !important;border-color:#2069a1 !important}
.btn-primary.disabled,.btn-primary:disabled{
    color:#fff;background-color:#1d4361 !important;border-color:#1d4361 !important;
}
.btn-primary:not(:disabled):not(.disabled).active,.btn-primary:not(:disabled):not(.disabled):active,.show>.btn-primary.dropdown-toggle{color:#fff;background-color:#1d4361 !important;border-color:#1d4361 !important;}

.save-model-dt {
    .datatable-body-cell-label {
        .search-cus-icon {
            color: #fff !important;
            border-radius: 50% !important;
            padding: 6px !important;
        }

        .text-wrap {
            text-overflow:unset !important;
        }
    }
}

.ant-modal-close-x .anticon {
    border-radius: 15px !important;
    background: #efedf3 !important;
    padding: 8px !important;
    font-size: 14px !important;
    color: #000 !important;
}
.search-cus-icon {
    color: #fff !important;
    border-radius: 50%;
    padding: 6px;
}


/***** Common CSS Start *****/
.c-btn-blue-dark {
    background: #143C5C !important;
    border: 1px solid #143C5C !important;
    color: #ffffff !important;
    border-radius: 6px;
}
.c-btn-blue-light {
    background: #ffffff !important;
    border: 1px solid #143C5C !important;
    color: #143C5C !important;
    border-radius: 6px;
}
.black-1 {
    color: #020520 !important;
}
.grey-2 {
    color: #7A7F87 !important;
}
.grey-3 {
    color: #525964 !important;
}
.grey-5 {
    color: #8A919C !important;
}
.grey-8 {
    color: #D4D7DE !important;
}
.fs-8 {
    font-size: 8px  !important;
}
.fs-12 {
    font-size: 12px  !important;
}
.fs-13 {
    font-size: 13px  !important;
}
.fs-14 {
    font-size: 14px  !important;
}
.fs-16 {
    font-size: 16px  !important;
}
.semi-letter-spacing {
    letter-spacing: 0.5px;
}
.fw-400 {
    font-weight: 400 !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.fw-700 {
    font-weight: 700 !important;
}
.border-grey-3 {
    border: 1px solid #525964 !important;
}
.background-red {
    background: #FF6B6B !important;
}
.text-red {
    color: #FF6B6B !important;
}
.pr-40 {
    padding-right: 40px !important;
}
.br-5 {
    border-radius: 5px !important;
}
.br-6 {
    border-radius: 5px !important;
}
.br-8 {
    border-radius: 5px !important;
}
.br-10 {
    border-radius: 10px !important;
}
.display-grid {
    display: grid !important;
}
.border-grey-5 {
    border: 1px solid rgba(0, 0, 0, 0.07) !important;
}

.c-dots-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table.table-v2.ngx-datatable {
    .datatable-header {
        .datatable-header-cell{
            border-bottom: 2px solid #E5E5E5;
            .datatable-header-cell-label {
                font-family: "DM Sans", sans-serif;
                text-transform: capitalize !important;
                color: #000000 !important;
                font-weight: bold !important;
                font-size: 12px !important;
            }
        }
    }
    .datatable-body {
        .datatable-row-wrapper {
            .datatable-body-cell {
                background-color: #ffffff !important;
                padding-left: 15px !important;
                .datatable-body-cell-label {
                    font-size: 12px;
                    color: #525964 !important;
                    .circle {
                        color: #ffffff !important;
                        span {
                            color: #ffffff !important;
                        }
                        &.dash-user-initial {
                            padding-left: 1px;
                            border: 1px solid #d9d9d9 !important;
                            background: #d9d9d9 !important;
                            color: #525964 !important;
                            border-radius: 100% !important;
                            font-size: 8px !important;
                            margin: 0px 5px 4px 0 !important;
                            &span {
                                color: #525964 !important;
                            }
                        }
                        &.top-0 {
                            top: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

table.table.table-v2 {
    thead {
        tr {
            th {
                font-family: "DM Sans", sans-serif;
                text-transform: capitalize !important;
                color: #000000 !important;
                font-weight: bold !important;
                font-size: 12px !important;
                border-bottom: 2px solid #E5E5E5;
            }
        }
    }
	tbody {
		tr {
			td {
				background-color: #ffffff !important;
                color: #525964 !important;
			}
		}
	}
    tfoot {
        tr {
			td {
                border-top: 2px solid #F3F4F6;
            }
        }
    }
}

.export-excel-icon {
    display: flex;
    align-items: center;
    img {
        width: 20px;
        height: 20px;
    }
}

.pagination-v2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &.p-3 {
        .page-count {
            padding-left: 0px !important;
        }
    }
    &.pr-3 {
        .page-count {
            padding-left: 0px !important;
        }
    }
    .page-count {
        padding-left: 15px !important;
        .page-show {
            font-size: 12px;
            font-weight: 400;
        }
        .page-select {
            font-size: 12px;
            font-weight: 400;
            border: 1px solid #E5E7EB;
            border-radius: 4px;
            padding: 4px 10px 4px 2px;
        }
        .page-total {
            font-size: 12px;
            font-weight: 400;
        }
    }
    .custom-datatable-pager {
        .pager {
            li {
                background: #F3F4F6;
                border-radius: 4px;
                margin-left: 5px !important;
                &.active {
                    background: #143C5C;
                }
                a {
                    font-weight: 400 !important;
                }
            }
        }
    }
    .custom-pg-pagination {
        .pagination {
            li {
                background: #F3F4F6;
                border-radius: 4px;
                margin-left: 5px !important;
                &.active {
                    background: #143C5C;
                }
                a {
                    font-weight: 400 !important;
                }
            }
        }
    }
}

.ant-modal-content {
    border-radius: 6px !important;
}

.c-modal {
    .c-modal-header {
        padding: 25px 15px 10px;
        font-size: 18px;
        // font-weight: 700;
        color: #020520;
    }
    .c-modal-body {
        thead {
            tr {
                th {
                    font-size: 14px;
                    font-weight: 700;
                    color: #020520;
                    text-transform: capitalize;
                    padding: 15px !important;
                }
            }
        }
        tbody {
            tr {
                td {
                    font-size: 14px;
                    font-weight: 400;
                    color: #525964;
                    padding: 15px !important;
                    .c-btn-modal-action {
                        font-size: 14px;
                        padding: 4px 12px;
                    }
                }
            }
        }
    }
}

.deleteViewModal {
    .ant-modal-close {
        display: none !important;
    }
    .ant-modal {
        width: 400px !important;
        .ant-modal-body {
            .modal-body {
                .c-btn-footer {
                    margin-top: 15px;
                    .cancelBtn {
                        border: 1px solid #525964;
                        background: transparent;
                        color: #525964;
                    }
                    .deleteBtn {
                        border: 1px solid #FF6B6B;
                        background: #FF6B6B;
                        color: #ffffff;
                    }
                }
            }
        }
    }
}

.unfollow-user {
    width: 25px;
    height: 25px;
    line-height: 25px !important;
    padding: 0 0 0 2px !important;
    text-align: center;
    border-radius: 100%;
    color: #ffffff;
}

.small-circle {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 11px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 10px;
    padding-left: 1px;
    position: relative;
    border-radius: 50%;
}

.cir-blue {
    background: #4472C4 !important;
    color: #ffffff !important;
    span {
        color: #ffffff !important;
    }
}

.cir-green {
    background: #3CAF91 !important;
    color: #ffffff !important;
    span {
        color: #ffffff !important;
    }
}

.cir-orange {
    background: #ffa500 !important;
    color: #ffffff !important;
    span {
        color: #ffffff !important;
    }
}

/* .page-sidebar {
    width: 90px !important;
    transform: none !important;
    .menu-items {
        .menu-item-single {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            margin: 10px auto !important;
            max-width: 75% !important;
            padding: 0 !important;
            a {
                display: none !important;
            }
            .icon-thumbnail {
                margin: 0 !important;
                max-width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #83AED0 !important;
                transform: none !important;
                i {
                    font-size: 25px;
                }
            }
            &:hover {
                background: rgba(28,79,120,0.5) !important;
            }
            &.active {
                background: rgba(28,79,120,0.5) !important;
                .icon-thumbnail {
                    color: #ffffff !important;
                    i {
                        color: #ffffff !important;
                    }
                }
            }
        }
    }
} */

/***** Common CSS End *****/

.adv-box-shadow {
    border-radius: 5px !important;
    box-sizing: border-box;
}

.removePadding{
    .ant-modal-body {
        padding:0 !important;
    }
}

.advisor-profile .header {
    background-color: #133C5C !important;
}

.c-dm-sans {
    font-family: "DM Sans", sans-serif !important;
}

.form-control {
    &.c-search-border {
        border: 1px solid #E6E8EC !important;
        border-radius: 4px !important;
    }
}
.sticky-success {
    background-color: #DAFFED !important;
    border-color: #DAFFED !important;
    color: #39A773 !important;
}
.sticky-error {
    background-color: #FFD6D6 !important;
    border-color: #FFD6D6 !important;
    color: #D8000C !important;
}
.contact-pagination-btn {
    background: #e9e9e9 !important;
    color: #000 !important;
    border-color: #e9e9e9 !important;
    padding: 8px 11px !important;
    border-radius: 4px !important;
}
.circle {
    &.dash-user-initial {
        padding-left: 1px;
        border: 1px solid #d9d9d9 !important;
        background: #d9d9d9 !important;
        color: #525964 !important;
        border-radius: 100% !important;
        font-size: 8px !important;
        margin: 0 5px 4px 0 !important;
        &span {
            color: #525964 !important;
        }
    }
}
.circle-auto {
    height: 70px !important;
    width: 70px !important;
    line-height: 70px !important;
    font-size: 20px !important;

    &.auto-user-initial {
        padding-left: 1px;
        border: 1px solid #d6d6d6 !important;
        background: #cde5fc !important;
        color: #6a92b8 !important;
        border-radius: 100% !important;
        font-size: 20px !important;
        margin: 0 5px 4px 0 !important;
        &span {
            color: #6a92b8 !important;
        }
    }
}
.dot-btn {
    border-radius: 100%;
    background: #5a5a5a;
    height: 5px;
    width: 5px;
    top: -1px;
    position: relative;
    margin: 0 6px;
}
.line-h-21 {
    line-height: 21px !important;
}
.top-0 {
    top: 0 !important;
}

.mapboxgl-popup-content{
    border: 1px solid #d1d5db;
    border-radius: 10px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

@keyframes leadScorePulse {
    0% {
      box-shadow: 0 0 0 0 rgba(184, 167, 237, 0.9);
    }
    50% {
      box-shadow: 0 0 0 6px rgba(184, 167, 237, 0.3);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(184, 167, 237, 0);
    }
}